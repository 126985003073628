<template>
  <SideBar :groups="groups"></SideBar>
</template>

<script>
import SideBar from '@/components/SideBar'
export default {
  components: {
    SideBar
  },

  data() {
    return {
      groups: [
        {
          label: '招聘信息',
          list: [
            {
              name: '招聘列表',
              path: '/mall/advertise/advertise'
            }
          ]
        }
      ]
    }
  }
}
</script>
